<template>
  <div :style="{'direction': $vs.rtl ? 'rtl' : 'ltr'}">
    <feather-icon @click="editRecord" icon="Edit3Icon" svgClasses="h-5 w-5 mr-4 hover:text-primary cursor-pointer"/>
<!--    <feather-icon @click="confirmDeleteRecord" icon="Trash2Icon" svgClasses="h-5 w-5 hover:text-danger cursor-pointer"/>-->
  </div>
</template>

<script>
export default {
  name: 'CellRendererActions',
  methods: {
    editRecord() {
      this.$router.push(`/a/attachments/${this.params.data.id}/edit`).catch(() => {
      });
    },
    confirmDeleteRecord() {
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',

        title: 'Confirm Archive',
        text: `Are you sure you want to archive this vide?`,
        accept: this.deleteRecord,
        acceptText: 'Archive',
      });
    },
    deleteRecord() {

      this.$http.delete(`billing/vides/${this.params.data.id}`)
        .then(response => {

          if (response.status === 200) {
            this.params.loadVideBuffer();

            return this.$vs.notify({
              color: 'success',
              title: 'Vide Archived',
              text: 'You have successfully archived this vide',
              position: 'top-right',
            });
          }

          this.showError({response});
        })
        .catch(this.showError);

    },
    showError(exception) {


      let error = 'An unknown error occurred while archive this vide';
      if (exception.response) {
        error = exception.response.data.data.message;
      }

      return this.$vs.notify({
        title: 'Failed to archive vide',
        text: error,
        color: 'danger',
        position: 'top-right',
      });
    },
  },
};
</script>
