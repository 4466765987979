<!-- =========================================================================================
  File Name: AddNewDataSidebar.vue
  Description: Add New Data - Sidebar component
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->


<template>
  <vs-sidebar v-model="isSidebarActiveLocal" class="add-new-data-sidebar items-no-padding" click-not-close color="primary"
              default-index="1" parent="body" position-right spacer>
    <div class="mt-6 flex items-center justify-between px-6">
      <h4>Add New Attachment</h4>
      <feather-icon class="cursor-pointer" icon="XIcon" @click.stop="isSidebarActiveLocal = false"></feather-icon>
    </div>
    <vs-divider class="mb-0"></vs-divider>

    <component :is="scrollbarTag" :key="$vs.rtl" :settings="settings" class="scroll-area--data-list-add-new">

      <div class="p-6">

        <!-- QUESTION -->
        <vs-textarea v-model="dataName" v-validate="'required'" class="mt-5 w-full" label="Attachment Name" name="attachment-name"
                     rows="3"/>
        <span v-show="errors.has('attachment-name')" class="text-danger text-sm">{{
            errors.first('attachment-name')
          }}</span>

        <vs-select v-model="dataType" label="Attachment Type">
          <vs-select-item value="hyperlink" text="Hyperlink"/>
          <vs-select-item value="file" text="File"/>
        </vs-select>


      </div>
    </component>

    <div slot="footer" class="flex flex-wrap items-center p-6">
      <vs-button :disabled="!isFormValid" class="mr-6" @click="submitData">Submit</vs-button>
      <vs-button color="danger" type="border" @click="isSidebarActiveLocal = false">Cancel</vs-button>
    </div>
  </vs-sidebar>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar';

export default {
  props: {
    isSidebarActive: {
      type: Boolean,
      required: true,
    },
    data: {
      type: Object,
      default: () => {
      },
    },
  },
  components: {
    VuePerfectScrollbar,
  },
  data() {
    return {

      dataId: null,
      dataName: '',
      dataType: '',

      settings: { // perfectscrollbar settings
        maxScrollbarLength: 60,
        wheelSpeed: .60,
      },
    };
  },
  watch: {
    isSidebarActive(val) {
      if (!val) return;
      this.initValues();
      this.$validator.reset();
      // Object.entries(this.data).length === 0 ? this.initValues() : { this.dataId, this.dataName, this.dataCategory, this.dataOrder_status, this.dataPrice } = JSON.parse(JSON.stringify(this.data))
    },
  },
  computed: {
    isSidebarActiveLocal: {
      get() {
        return this.isSidebarActive;
      },
      set(val) {
        if (!val) {
          this.$emit('closeSidebar');
          // this.$validator.reset()
          // this.initValues()
        }
      },
    },
    isFormValid() {
      return !this.errors.any() && this.dataName;
    },
    scrollbarTag() {
      return this.$store.getters.scrollbarTag;
    },
  },
  methods: {
    initValues() {
      if (this.data.id) return;
      this.dataId = null;
      this.dataQuestion = '';
      this.dataAnswer = '';
    },
    submitData() {
      this.$validator.validateAll().then(result => {

        if (result) {
          const obj = {
            name: this.dataName,
            type: this.dataType
          };

          this.$http
            .post('attachments', obj)
            .then((response) => {
              this.$emit('closeSidebar');

              if (response.data) {
                const payload = response.data.data;

                if (payload.id) {
                  this.$router.push(`/a/attachments/${payload.id}/edit`);
                }

              }

              return this.$vs.notify({
                color: 'success',
                title: 'Created Attachment',
                text: 'Your attachment has been created',
              });
            })
            .catch(exception => {

              let details = 'An unknown error occurred while creating the Attachment. This has been reported.';

              if (exception.response) {

                const response = exception.response;

                if (response.status === 400) {
                  details = response.data.error.description;
                }

              }

              return this.$vs.notify({
                title: 'Failed to create Attachment',
                text: details,
                color: 'danger',
              });
            });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.add-new-data-sidebar {
  ::v-deep .vs-sidebar--background {
    z-index: 52010;
  }

  ::v-deep .vs-sidebar {
    z-index: 52010;
    width: 400px;
    max-width: 90vw;

    .img-upload {
      margin-top: 2rem;

      .con-img-upload {
        padding: 0;
      }

      .con-input-upload {
        width: 100%;
        margin: 0;
      }
    }
  }
}

.scroll-area--data-list-add-new {
  // height: calc(var(--vh, 1vh) * 100 - 4.3rem);
  height: calc(var(--vh, 1vh) * 100 - 16px - 45px - 82px);

  &:not(.ps) {
    overflow-y: auto;
  }
}
</style>
